/* .model-sales-invoice-GCVanMaster-Modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 80vh;
  background-color: var(--commonText);
  border: 2px solid var(--label);
  box-shadow: 24;
  padding: 15px;
} */
.me-return-request-review {
  margin-right: 3rem !important;
}
.mb-return-request-review {
  margin-bottom: 1.5rem !important;
}
.return-request-review-m {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .me-return-request-review {
    margin-right: 0rem !important;
  }
  .mb-return-request-review {
    margin-bottom: 0rem !important;
  }
  .return-request-review-m {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 390px) {
  /* .model-sales-invoice,
  .model-sales-invoice-GCVanMaster-Modal {
    padding: 15px 0px;
  } */
}

.mui-outlet-report-grid-header {
  /* background-color: rgb(239 235 235); */
  /* background-color: #2771a3; */
  background-color: var(--primary);
  color: #fff;
  font-weight: bold;
}

.mui-request-grid-final-qty {
  color: #d32f2f;
  border-bottom: 2px solid #00000073 !important;
  border-radius: 0px 0px 8px 8px;
}

.mui-request-grid-unit {
  color: #d32f2f;
  border-bottom: 2px solid #00000073 !important;
  border-radius: 0px 0px 8px 8px;
  padding: 0px !important;
}

.modal-req_itm-col-modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  height: 60vh;
  background-color: var(--commonText);
  border: 2px solid var(--label);
  box-shadow: 24;
}
.mui-transfer-grid-unit {
  padding: 0 !important;
  border-radius: 0px 0px 8px 8px;
  border-bottom: 2px solid #00000073 !important;
}
.model-gc-item2 {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:900px;
  height: 90vh;
  background-color: var(--commonText);
  border: 1px solid var(--primary);
  box-shadow: 24;
}
@media screen and (max-width: 800px) { 
  .model-gc-item2  {
  width: 95%;
  height: 90vh;
}
}
.order-Modal-title {
  background-color: var(--primary);
  color: var(--commonText);
  align-items: center;
  padding: 10px 10px;
  font-weight: 500;
}

.modal-purchase-Modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 80vh;
  background-color: var(--commonText);
  border: 2px solid var(--label);
  box-shadow: 24;
  /* padding: 15px; */
}

.modal-puchase-adjustment-Modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  height: 70vh;
  background-color: var(--commonText);
  border: 2px solid var(--label);
  box-shadow: 24;
  /* padding: 15px; */
}

.modal-puchase-grid-options-Modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 25vh;
  background-color: var(--commonText);
  border: 2px solid var(--label);
  box-shadow: 24;
  /* padding: 15px; */
}

@media screen and (max-width: 1000px) {
  .modal-purchase-Modal {
    width: 95%;
  }

  .modal-puchase-adjustment-Modal {
    width: 85%;
  }

  .modal-puchase-grid-options-Modal {
    width: 50%;
  }
}

.post-Modal-title {
  background-color: var(--primary);
  color: var(--commonText);
  align-items: center;
  padding: 10px 10px;
  font-weight: 500;
}

.post-Modal-menu {
  margin: 10px 20px;
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.post-Modal-collapse {
  height: 250px;
  width: 100%;
  margin-top: 10px;
}

@media screen and (max-height: 950px) {
  .post-Modal-collapse {
    height: 280px;
  }

  .post-Modal-menu {
    margin: 10px 10px;
    padding: 10px;
  }
}

@media screen and (max-height: 750px) {
  .post-Modal-collapse {
    height: 200px;
  }

  .post-Modal-menu {
    margin: 10px 10px;
    padding: 10px;
  }
}

.overlay-custom-class-name {
  z-index: 100;
}

/* width */
.Order::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.Order::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--tertiary);
  border-radius: 5px;
}

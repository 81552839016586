.border-card {
  border-left: 6px solid var(--primary) !important;
  border-top: 1px solid var(--card-border) !important;
  border-bottom: 1px solid var(--card-border) !important;
  border-right: 1px solid var(--card-border) !important;
  border-radius: 10px 8px 8px 10px;
  padding: 20px !important;
}

.border-card-yellow {
  border-left: 6px solid var(--card-border-yellow) !important;
  border-top: 1px solid var(--card-border) !important;
  border-bottom: 1px solid var(--card-border) !important;
  border-right: 1px solid var(--card-border) !important;
  border-radius: 10px 8px 8px 10px;
  padding: 25px !important;
}

.border-card-green {
  border-left: 6px solid var(--card-border-green) !important;
  border-top: 1px solid var(--card-border) !important;
  border-bottom: 1px solid var(--card-border) !important;
  border-right: 1px solid var(--card-border) !important;
  border-radius: 10px 8px 8px 10px;
  padding: 20px !important;
}

.txt-overflow-ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* width */
.ReviewBatch::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.ReviewBatch::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--tertiary);
  border-radius: 5px;
}

/* Handle */
.ReviewBatch::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}
.scrol-overflow1 {
  height: 50vh;
  overflow-y: auto;
}
@media screen and (max-width: 390px) {
  .border-card {
    padding: 10px !important;
  }
  .scrol-overflow1 {
    height: 55vh;
    overflow-y: auto;
  }
}

/* .breadcrumbOl {
  list-style-type: none;
} */

/* .breadcrumbtestOl {
  background: #ff661a;
  display: inline-block;
  position: relative;
} */

.breadcrumbtest::before {
  content: '';
  position: absolute;
  left: 0;
  height: 50%;
  width: 100%;
  background: #0ed9f4;
  z-index: -1;
  top: 0;
  transform: skew(30deg);
}

.breadcrumbtest::after {
  content: '';
  position: absolute;
  left: 0;
  height: 50%;
  width: 100%;
  background: #0ed9f4;
  z-index: -1;
  bottom: 0;
  transform: skew(-30deg);
}

.breadcrumbtest {
  display: inline-block;
  position: relative;
  line-height: 1.8;
  padding: 0 20px;
  color: #fff;
}

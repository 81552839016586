.border-card {
  border-left: 6px solid var(--primary) !important;
  border-top: 1px solid var(--card-border) !important;
  border-bottom: 1px solid var(--card-border) !important;
  border-right: 1px solid var(--card-border) !important;
  border-radius: 10px 8px 8px 10px;
  padding: 25px !important;
}
.txt-overflow-ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* width */
.Dispatch::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.Dispatch::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--tertiary);
  border-radius: 5px;
}

/* Handle */
.Dispatch::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}
.scrol-overflow-dispatch {
  height: 54vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 390px) {
  .border-card {
    padding: 10px !important;
  }
}

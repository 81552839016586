.me-return-request {
  margin-right: 3rem !important;
}
.mb-return-request {
  margin-bottom: 1.5rem !important;
}
.me-return-dcReceipt {
  margin-right: 6rem !important;
}
.css-xtlx1y-MuiGrid-root>.MuiGrid-item {
  padding-top: 26px !important;
}
@media screen and (max-width: 768px) {
  .me-return-request {
    margin-right: 0rem !important;
  }
  .mb-return-request {
    margin-bottom: 2rem !important;
  }
  .me-return-dcReceipt {
    margin-right: 2rem !important;
  }
}
@media screen and (max-width: 450px) {
  .me-return-request {
    margin-right: 0rem !important;
  }
  .me-return-dcReceipt {
    margin-right: 0rem !important;
  }
  .css-xtlx1y-MuiGrid-root>.MuiGrid-item {
    padding-top: 12px !important;
}
}

.tablehead th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}
.tablerow tr {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}
.GCTABLE {
  overflow-x: scroll;
}
.item-search {
  background-color: var(--primary);
  color: var(--commonText);
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  font-size: var(--laptop-font);
}
.gnr-item-search-label {
  font-size: var(--laptop-font);
  color: var(--primary);
}
.GCItemSearch-Modal {
  overflow: scroll;
}
.search-btn {
  background-color: var(--primary);
  width:50%;
  padding:3px
}
.model-gc-search {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90vh;
  background-color: var(--commonText);
  border: 1px solid var(--primary);
  box-shadow: 24;
}
.model-gc-item {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 98%;
  height: 90vh;
  background-color: var(--commonText);
  border: 1px solid var(--primary);
  box-shadow: 24;
}
.model-gc-search .css-isbt42 {
  margin-top: 0px;
  width: 100%;
  margin-left: 0px;
}
.GCSearchBtn-Tittle {
  background-color: var(--primary) !important;
  color: var(--commonText) !important;
  font-size: var(--title);
  font-size: 18px;
  padding: 5px 0px;
}
.mui-text-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.mui-text-field .css-1x5jdmq {
  padding: 6.5px 14px !important;
}

.model-GCSearchBtn {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80vh;
  background-color: var(--commonText);
  border: 1px solid var(--primary);
  box-shadow: 24;

  overflow: auto;
}
@media screen and (max-width: 800px) { 
  .model-GCSearchBtn {
  width: 95%;
  height: 90vh;
}
}
@media screen and (max-width: 400px) {
  .gnr-item-search-label {
    font-size: var(--mobile-font) !important;
    color: var(--primary);
  }
  
}
.GCSearchBtn-Tittle {
  background-color: var(--primary) !important;
  color: var(--commonText) !important;
  /* / font-size: var(--title); / */
  font-size: 14px;
  padding: 5px 0px;
}

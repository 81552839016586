.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: var(--react-confirm-alert-h1);
}

.react-confirm-alert-button-group > button {
  background: var(--primary);
}

.dashboardMenu {
  display: flex;
  height: 100vh;
  align-items: center;
}
/* 
.menu-icon-text {
  color: var(--primary);
  margin-top: 10px;
  font-size: 14px;
} */

.main-menu-icon .fas,
.main-menu-icon .fad {
  font-size: 40px;
}

.oval-btn {
  background: var(----secondary) !important;
  color: var(--primary) !important;
  /* padding: 14px 23px!important; */
  border: 2px solid var(--primary) !important;
  border-radius: 30px !important;
  width: 25%;
}

.Notification-btn-p {
  padding: 14px 30px !important;
}

.oval-btn-p {
  padding: 14px 22px !important;
}

.Dispatch-btn-p {
  padding: 14px 41px !important;
}

.Loading-btn-p {
  padding: 14px 44px !important;
}

.BinTransfer-btn-p {
  padding: 14px 33px !important;
}

.SalesInvoice-btn-p {
  padding: 14px 29px !important;
}

.HomePage {
  height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-single-line-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

button.btn-home {
  text-transform: none;
  font-size: var(--label-Font);
}

@media screen and (max-width: 800px) {
  .oval-btn {
    width: 60%;
  }
  button.btn-home {
    font-size: var(--tab-font);
  }
}
@media screen and (max-width: 400px) {
  .oval-btn {
    width: 60%;
  }
  button.btn-home {
    font-size: var(--mobile-font);
  }
}

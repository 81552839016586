:root {
  --secondary: #297ccf;
  --commonText: #fff;
  --text: #211111;
  --font-family: 'Arial, Helvetica, sans-serif';
}

.notion-container{
  max-width: 90% !important;
  margin: 0 auto;
}
.notion-info .MuiGrid-item {
  padding-top: 26px !important;
}
.notion-infoScr .MuiGrid-item {
  padding-top: 26px !important;
}
.notion-info .MuiInput-input {
  padding: 2px !important;
  font-size: 13px !important;
}
.notion-card .MuiCardContent-root:last-child {
  padding: 16px !important;
}
.notion-card {
  border-radius: 8px !important;
  border-left-width: 5px !important;
  border: 1px solid var(--secondary) !important;
  border-left: 5px solid var(--secondary) !important;
  box-shadow: 0px 1px 3px var(--secondary) !important;
}

.custom-header {
  background-color: #f5f5f5; /* Replace with your desired color */
  color: #000; /* Optional: set the text color */
}

.notion-grid .MuiDataGrid-columnHeaders {
  min-height: 48px !important;
  max-height: 48px !important;
  line-height: 48px !important;
  background-color: var(--secondary) !important;
  color: var(--commonText) !important;
}

.notion-grid {
  border: 1px solid var(--secondary) !important;
}
.notion-grid .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
  max-height: 40px !important;
  min-height: 40px !important;
}
.notion-grid .MuiDataGrid-row {
  max-height: 40px !important;
  min-height: 40px !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}

.notion-search {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  background-color: var(--secondary);
  color: var(--commonText);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.inputItem .MuiInputBase-input {
  padding: 3.3px 5px !important;
}

.inputItem .MuiInputBase-root:focus {
  border-color: none !important;
}

.inputItem .MuiInputBase-root {
  padding-right: 0px !important;
}
.alert-meg {
  font-size: 16px !important;
  text-decoration: underline;
  color: var(--text) !important;
  font-family: var(--font-family) !important;
}
.alertMeg {
  font-size: 14px !important;
  padding-left: 24px !important;
  color: var(--text) !important;
  font-family: var(--font-family) !important;
}
.notion-page .react-confirm-alert .react-confirm-alert-button-group {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  width: 100%;
}

.notion-page .alertBtn {
  padding: 8px 16px;
}
/* Additional styling if needed */
.notionBtn .react-confirm-alert-button-group {
  display: flex !important;
  justify-content: space-evenly !important;
}

.alertmeg {
  font-size: 16px !important;
  color: var(--text) !important;
  font-family: var(--font-family) !important;
}

@media screen and (max-width: 450px) {
  .notion-info .MuiInput-input {
    font-size: 0.6rem !important;
  }
  .notion-infoScr .MuiGrid-item {
    padding-top: 10px !important;
  }
  .notion-search {
    padding: 4px 8px;
  }
  .inputItem .MuiInputBase-input {
    padding: 5.8px !important;
  }
  .react-confirm-alert-body {
    width: 340px !important;
  }
}
